@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  color-scheme: dark;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: black;
}

.font-m {
  font-family: "Montserrat", sans-serif;
}

.g-box {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  animation: gradient 15s ease infinite;
  background-size: 300% 300%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mor-bg {
  background: rgb(0 0 0 / 55%);
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 37%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.mor-bg-2 {
  background: rgb(0 0 0 / 35%);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.float {
  animation: float 8s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.blur {
  filter: blur(150px);
}
